import React from 'react'
import { Router } from '@reach/router'
import _ from 'lodash'
import PagesScreen from '../screens/pages'

const PagesPage = (props) => (
  <Router>
    <PagesScreen {...props} path={`${_.get(props, 'pageContext.locale')}/pages/by_code/:slug`} />
    <PagesScreen {...props} path={`${_.get(props, 'pageContext.locale')}/pages/:id/:slug`} />
    <PagesScreen {...props} path={`${_.get(props, 'pageContext.locale')}/pages/:id`} />
  </Router>
)

export default PagesPage
