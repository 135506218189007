/* eslint-disable react/no-danger */
import React from 'react'
import useStyles from './pages-style'
import {
  Layout,
  Loading,
  SEO,
} from '../../ui'

function PagesView({
  page,
  pageReady,
  seoDescription,
  seoTitle,
  seoLinks,
}) {
  const { pages, contianerContent } = useStyles()
  return (
    <>
      {pageReady ? (
        <Layout>
          <SEO
            description={seoDescription}
            title={seoTitle}
            link={seoLinks}
          />
          <div className={pages}>
            <div className={contianerContent}>
              <article dangerouslySetInnerHTML={{ __html: page.content }} />
            </div>
          </div>
        </Layout>
      ) : (<Loading />)}

    </>
  )
}

export default PagesView
